import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import TopImageBlue from '../components/TopImageBlue'
import TopImageGreen from '../components/TopImageGreen'
import BreadCrumbs from '../components/BreadCrumbs'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import JirkaPhoto from '../img/jirka.png'
import { Icon } from '../components/builders/icon-builder'
import FormBuilder from '../components/builders/form-builder'
import { getLocalizedUrl } from '../utils/localeURL'
import { t } from 'ttag'

const Division = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignDivisionPage[0]

  const topImageProps = {
    title: page.displayText,
    text: page.divisionPageTopImageDescription.html,
    animationStyle: page.divisionPageAnimation,
    links: {
      main: `${getLocalizedUrl(page.path)}#top`,
      contact: `${getLocalizedUrl(page.path)}#contact`
    }
  }

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs
        default={true}
        theme={page.puxDesignThemeSelector.puxDesignThemeSelectorTheme}
        center={false}
        items={[
          {
            path: page.path,
            displayText: page.displayText,
          },
        ]}
      />
      <section className='division-ti'>
        {page.puxDesignThemeSelector.puxDesignThemeSelectorTheme === `theme-green` ? (
          <TopImageGreen {...topImageProps} />
        ) : (
          <TopImageBlue {...topImageProps} />
        )}
      </section>
      <div id="top" className='relative-anchor'></div>
      <div className={page.puxDesignThemeSelector.puxDesignThemeSelectorTheme}>
        <div className={page.puxDesignThemeSelector.puxDesignThemeSelectorTheme}>
          <div className="division-widgets">
            <WidgetBuilder widgetBuilderData={pageContext.widgets} />
          </div>
        </div>


        <div id="contact" className='relative-anchor'></div>
        <section className={`triangle-cross service-form-section`} >
          <div id="sib-form-scroll-helper" className="relative-anchor secondary-nav-helper"></div>
          <div className="pux-container narrow">
            <div className="form-info">
              <h2 className="mb-0">{page.divisionPageFormHeading}</h2>
              <div className="perex">{page.divisionPageFormDescription}</div>
              <div className="info-tom-wrap">
                <div className="info-tom">
                  <img src={JirkaPhoto} alt="Jiří Juhaňák"></img>
                  <strong>
                    Jiří Juhaňák
                  </strong>
                  <span> / KONZULTANT</span>
                </div>
                <div className="info-tom-phone">
                  <a href="tel:+420605175519">
                    <Icon id="mobile" color="#FFFFFF" />
                    +420 605 175 519
                  </a>
                </div>
                <div className="info-tom-mail">
                  <a href="mailto:jiri.juhanak@puxdesign.cz">
                    <Icon id="mail" color="#FFFFFF" />
                    <span className="btn-link">jiri.juhanak@puxdesign.cz</span>
                  </a>
                </div>
              </div>
            </div>
            <FormBuilder formId="general" recaptchaUrl={pageContext.recaptchaUrl} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Division

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignDivisionPage(first: 1, where: { path: $pagePath }) {
        path
        displayText
        divisionPageAnimation
        divisionPageFormHeading
        divisionPageFormDescription
        divisionPageTopImageDescription {
          html
        }
        puxDesignThemeSelector {
          puxDesignThemeSelectorTheme
        }
        localization {
          localizations {
            ... on Orchard_PuxDesignDivisionPage{
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`
